import {request, request_op} from "../plugins/axios";

//获取智能表单
export function getSuperForm (params) {
    return request({
        url: '/superForm/getSuperForm',
        method: 'GET',
        params: params
    })
}

//提交智能表单
export function submitData (params) {
    return request({
        url: '/superForm/submitData',
        method: 'put',
        data: params
    })
}

//获取微信用户信息
export function openUserInfoApi (params) {
    return request_op({
        url: '/openUserInfo/superForm',
        method: 'GET',
        params: params
    })
}