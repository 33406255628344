<template>
  <div>
    <nav-bar
      :title="dataObj.title"
      left-text="返回"
      left-arrow
      @click-left="$router.back()"
    >
    </nav-bar>
    <div class="ul_right">
      <Popup
      v-model="area_show_s"
      round
      position="bottom">
      <Area title="地址" @confirm="areaChaange" @cancel="area_show_s = false" :area-list="areaList" value="110101" />
    </Popup>
      <div class="ul_model_body">
 
        <div class="banner_img">
          <img :src="form.form_cover" style="width:100%; height:100%;"/>
        </div>
        <div class="model_p">
          {{dataObj.desc || '友情提示：为了将任务奖励成功发放给您，需要您完成一下信息的填写，感谢您的配合'}}
        </div>
        <div class="model_form">
          <div class="item item_s" v-for="(item,index) in form.formList" :key="index">
            <div v-if="item.name == 'address'">
              <div class="label" :class="item.rules">{{ item.label }}</div>
              <div class="on_show">
                <Cell  :value="areaName"  is-link @click="onAreaShow()" />
              </div>
              
             
              <textarea  v-model="areaData"  placeholder="" class="textarea_class"></textarea>
            </div>
            <div v-else>
              <div class="label" :class="item.rules">{{ item.label }}</div>
              <div class="input">
                <input placeholder="" v-model="item.value"  :type="item.name == 'mobile' ? 'number' : '' " />
              </div>
            </div>

          </div>
        </div>
      </div>
      
      <button type="warning" size="small" @click="submitForm" class="buttons">提交</button>
    </div>

   

  </div>
</template>

<script>
import { Area, Popup, Cell,NavBar,Button,Toast ,Dialog  } from 'vant'
import { areaList } from '@vant/area-data'
import {openUserInfoApi,getSuperForm,submitData} from "@/api/superForm";
export default {
  components: {
    Area,
    Popup,
    Cell,
    NavBar,
    Button 
  },
  data () {
    return {
      url: window.location.href,
      id:'',
      areaList,
      dataObj:{},
      form: {
        formList: [
          {
            label: '姓名',
            name: 'name',
            required: true,
            type: 'input',
            value: ''
          },
          {
            label: '手机号',
            name: 'phone',
            required: true,
            type: 'input',
            value: ''
          }, {
            label: '地址',
            name: 'address',
            required: true,
            type: 'input',
            value: ''
          }
        ]
      },
      area_show_s: false,
      weChatUserNews: {},
      area: [],
      areaName:'',
      areaData:'',
    }
  },
  // watch: {
  //   serchValue () {
  //     this.reacquire()
  //   }
  // },
  created() {
      //  获取参数
      this.id = this.$route.query.id;
      this.getOpenUserInfo();
  },
  methods: {
    areaChaange(e) {
      this.area = e;
      this.areaName = e[0].name + e[1].name + e[2].name
      this.area_show_s = false
      console.info(e)
    },  
    onAreaShow () {
      this.area_show_s = true
    },
    submitForm() {
      console.info(this.form.formList)
      let num = -1;
      this.form.formList.forEach((item,index) => {
        if (item.name == 'address') {
          if (this.area.length > 0 || this.areaData != '') {
            item.value = this.areaName + this.areaData
            this.form.formList[index]['areaCode']=this.area
          }
        }
        
        if (item.rules.indexOf('required') != -1) {
         
          if (!item.value) {
            Toast.fail(item.label + '不能为空')  
            num = 1;
            return 
          }; 
        }
      })
      
      if (num == 1) {
        return false
      }
      submitData({
        form_id: this.id,
        union_id: this.weChatUserNews.unionid,
        nickname: this.weChatUserNews.nickname,
        avatar: this.weChatUserNews.headimgurl,
        form_data: this.form.formList,
      }).then((res) => {
        this.$router.push({
          path: '/formSuccess', query: {
            commit_note:  this.dataObj.commitNote, // 提交提示语
            corp_name:  this.dataObj.corpName, //企业名称
            corp_mobile:  this.dataObj.corpMobile, // 企业电话
            corp_nickname:  this.dataObj.corpNickname, //企业昵称
            qrcode_note: this.dataObj.qrcodeNote, //二维码提示语
            corp_mobile_status: this.dataObj.corpMobileStatus, // 是否手机号显示
            corp_nickname_status:this.dataObj.corpNicknameStatus, // 是否头像显示
            corp_avatar:  this.dataObj.corpAvatar, //头像
            qrcode: this.dataObj.qrcode, // 二维码
          }
        })
      })
      // this.$router.push({ path: '/formSuccess', query: { type: 1 } })
    },
    getOpenUserInfo() {
        let that = this;
        openUserInfoApi({
            id: that.id
        }).then((res) => {
            if (res.data.openid === undefined) {
                let redirectUrl = '/auth/superForm?id=' + that.id + '&target=' + encodeURIComponent(that.url);
                console.info(redirectUrl)
                that.$redirectAuth(redirectUrl);
            }
            console.info(res.data)
            this.weChatUserNews = res.data;
            // this.weChatUserNews = {
            //   "openid": "ozd6v6kmZpyzqZNslWaVwqyPrCg8",
            //   "nickname": "sakura",
            //   "sex": 0,
            //   "language": "",
            //   "city": "",
            //   "province": "",
            //   "country": "",
            //   "headimgurl": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTI9ia9N627OLaUickajtEVGicia5ody3FkiaWCcjvHXBGnNaiaTQl7qg5rqJibicW8HS73bgpnzlkAB1EDC9Q/132",
            //   "privilege": [],
            //   "unionid": "o4MQT56MMwwSvbWTbR6Nnl1ZbDIk"
            // }
            this.getSuperFormData();
        });
    },
    getSuperFormData() {
      getSuperForm({
                  form_id: this.id,
                  union_id: this.weChatUserNews.unionid,
                  nickname: this.weChatUserNews.nickname,
                  avatar: this.weChatUserNews.headimgurl,
      }).then((res) => {
        let arr = [];
        this.dataObj = res.data
        res.data.fields.forEach((item) => {
          let str = {
            label: item.label,
            name: item.name,
            rules:JSON.parse(item.rules).length > 0 ? JSON.parse(item.rules).join(' ') : '',
            type: item.type,
            value: ''
          }
          arr.push(str)
        })
        if (res.data.fields.length == 0) {
          Dialog.alert({
            title: '错误',
            message: '获取数据失败，请重试'
          }).then(() => {
            location.reload()
            console.info('刷新')
          }).catch(() => {
            // on cancel
          });
        }
        this.form.form_cover = res.data.formCover
        this.form.formList = arr
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style scoped lang="less">
.model_form{
  padding: 0 15px;
  font-size: 14px;
}
.on_show{
  border: solid 1px #d7d7d7;
  border-radius: 5px;
  margin-bottom: 10px;
}
.buttons{
  border-radius: 5px;
  background: #f5222d;
  width: 300px;
  line-height: 40px;
  margin-left: 50%;
  margin-right: -50%;
  margin-bottom: 200px;
  transform: translateX(-50%);
  border: 0;
  font-size: 16px;
  color: #fff;
}
.textarea_class{
  border: solid 1px #d7d7d7;
  font-size: 14px;
  width: 100%;
  height: 200px;
}
.ul_right{
  width: 100%;


}
.item{
  position: relative;
}
.label{
  font-size: 14px;
  line-height: 30px;
  margin-top: 20px;
}
.input{
  input{
    width: 100%;
    height: 40px;
    border: solid 1px #d7d7d7;
    font-size: 14px;
    border-radius: 5px;
  }
}

.required:after {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 20px;
    line-height: 1;
    position: absolute;
    left: -10px;
    top: 6px;
}
.ul_model_body{
  background: #fff;
  min-height: 600px;
  width: 100%;
  padding-bottom: 100px;

  .title_close{
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    line-height: 40px;
    .close_left{
      position: absolute;
      left: 0px;
    }
  }
  .banner_img{
    height: 200px;
    background: #e8eaec;
  }
  .model_p{
    margin: 10px 10px;
    font-size: 14px;
  }
}
/deep/.van-empty{
  padding-bottom: 0;
  margin-bottom: -30px;
}
/deep/.van-empty__image{
  width: 200px;
img{
  object-fit: cover;
}
}
/deep/.ant-spin-lg .ant-spin-dot {
  font-size: 50px;
}
/deep/.ant-spin-lg .ant-spin-dot i {
  width: 23px;
  height: 23px;
}
//话术顶部样式
.top-row{
  background: #fff;
  padding-right: 22px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  .speech-search{
    width: calc(100% - 150px);
  }
  .news-btn{
    display: block;
    width: 150px;
    font-size: 22px;
    height: 60px;
  }
}
.radar-list{
  padding:0 30px 30px 30px;
  overflow-y: scroll;
  background: #efeff4;
  padding-bottom: 140px;
  min-height: auto;
  position: relative;
  .loading{
    position: fixed;
    /* background: red; */
    top: 0;
    left: 50%;
    bottom: 0;
    /* right: 0; */
    transform: translate(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.radar-tip{
  margin-top: 30px;
  color: #B4B5B7;
}
.speech-box{
  background: #fff;
  border-radius: 10px;
  padding: 30px 30px 40px 30px;
  margin-top: 30px;
}
.speech-name{
  font-size: 28px;
  font-weight: bold;
}
.speech-cont{
  margin-top: 20px;
  background: #F7F8F9;
  padding: 20px;
  display: flex;
  align-items: center;
  span{
    max-width: 225px;
    overflow: auto;
    white-space: nowrap;
    color: #4a4a4a !important;
  }
}
.speech-foot{
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin-top: 40px;
}
.radar-cont{
  padding-left: 65px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  img{
    width: 130px;
    height: 120px;
  }
}
.radar-rgh{
  margin-left: 10px;
  width: calc(100% - 150px);
  color: #8F8F8F;
  font-size: 26px;
  .radar-brief{
    -webkit-box-flex: 1;
    flex: 1;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
}
</style>
