<template>
  <div>
    <van-nav-bar
      title="提交完成"
      left-text="返回"
      left-arrow
      @click-left="$router.back()"
    >

    </van-nav-bar>
    <div class="ul_right">
      <div class="ul_model_body">
        <!-- <div class="title_close">
          <span class="close_left"><a-icon type="close" /></span>
          <div class="title_h1">提交完成</div>
        </div> -->
        <div class="title_body">
          <div class="check_icon">
            <a-icon type="check-circle" theme="filled" style="fontSize:40px;" />
            
          </div>
          <div>
            <div class="title_h1">提交成功</div>
            <div class="title_p">{{bind_form.commit_note}}</div>
          </div>
        </div>

        <div class="model_form">
          <div class="model_user">
            <div class="model_user_img" v-show="Number(bind_form.corp_nickname_status)">
              <img :src="bind_form.corp_avatar" style="width:100%;height:100%"/>
            </div>
            <div class="model_user_p">
              <div>{{ bind_form.corp_name }}</div>
              <div v-show="Number(bind_form.corp_nickname_status)" >{{ bind_form.corp_nickname  }}</div>
              <div v-show="Number(bind_form.corp_mobile_status)"><a-icon type="phone" theme="filled" /> {{ bind_form.corp_mobile }}</div>
            </div>
          </div>
        </div>
        <div class="code_body">
          <div class="code_img">
            <img :src="bind_form.qrcode" style="width:100%;height:100%"/>
          </div>

          <div>{{bind_form.qrcode_note}}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Area, Popup, Cell,Icon  } from 'vant'

export default {
  components: {
    Area,
    Popup,
    Cell
  },
  data () {
    return {
      bind_form: {
        commit_note:  '', // 提交提示语
        corp_name:  '', //企业名称
        corp_mobile:  '', // 企业电话
        corp_nickname:  '', //企业昵称
        qrcode_note: '', //二维码提示语
        corp_mobile_status: '', // 是否手机号显示
        corp_nickname_status:'', // 是否头像显示
        corp_avatar:  '', //头像
        qrcode:'', // 二维码
      }
    }
  },
  watch: {
    serchValue () {
      this.reacquire()
    }
  },
  created() {
      //  获取参数
    this.bind_form = this.$route.query;
     
  },
  methods: {
    onAreaShow () {
      this.area_show_s = true
    }

  }
}
</script>

<style scoped lang="less">
*{
  font-size: 14px;
}
.title_body{
  display: flex;
  border-bottom: solid  2px #f2f2f2;
  padding: 20px 0;
  .check_icon{
    color: #18be6b;
    width: 60px;
    margin-top: 2px;
  }
}
.title_h1{
  font-weight: bolder;
  font-size: 18px;
}
.title_p{
  
  font-size: 12px;
}
.code_body{
  text-align: center;
  line-height: 50px;
  .code_img{
    width: 240px;
    height: 240px;
    background: #f8f8f9;
    margin: auto;
  }
}
.model_form{
  padding: 20px 0;
  margin-bottom: 20px;
  border-bottom: solid  2px #f2f2f2;
}
.model_user{
  display: flex;
  .model_user_p{
    line-height: 23px;
  }
  .model_user_img{
    width: 70px;
    height: 70px;
    margin-right: 15px;
    border-radius: 5px;
    background: #f8f8f9;
  }
}
.page_style{

  background: #f8f8f9;
  border-radius: 3px;
  width: 600px;
  padding: 20px;
  margin: 20px 0;
  margin-left: 100px;

}
.ul_right{
  width: 100%;
  padding: 15px 20px;
}
.ul_model_body{
  background: #fff;
  min-height: 600px;
  width: 100%;
  padding: 10px 20px;
  padding-bottom: 40px;
  .title_close{
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    line-height: 40px;
    .close_left{
      position: absolute;
      left: 0px;
    }
  }

}
.item{
  position: relative;
}
.label{
  font-size: 14px;
  line-height: 50px;
  margin-top: 20px;
}
.input{
  input{
    width: 100%;
    height: 80px;
    border: solid 1px #d7d7d7;
    font-size: 14px;
    border-radius: 10px;
  }
}

.required:after {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    line-height: 1;
    position: absolute;
    left: -10px;
    top: 6px;
}
.ul_model_body{
  background: #fff;
  min-height: 600px;
  width: 100%;
  padding: 10px 20px;
  padding-bottom: 100px;

  .title_close{
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    position: relative;
    line-height: 40px;
    .close_left{
      position: absolute;
      left: 0px;
    }
  }
  .banner_img{
    height: 250px;
    margin: 20px 0;
    background: #e8eaec;
  }
  .model_p{
    margin: 10px 0;
    font-size: 14px;
  }
}
/deep/.van-empty{
  padding-bottom: 0;
  margin-bottom: -30px;
}
/deep/.van-empty__image{
  width: 200px;
img{
  object-fit: cover;
}
}
/deep/.ant-spin-lg .ant-spin-dot {
  font-size: 14px;
}
/deep/.ant-spin-lg .ant-spin-dot i {
  width: 23px;
  height: 23px;
}
//话术顶部样式
.top-row{
  background: #fff;
  padding-right: 22px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  .speech-search{
    width: calc(100% - 150px);
  }
  .news-btn{
    display: block;
    width: 150px;
    font-size: 14px;
    height: 60px;
  }
}
.radar-list{
  padding:0 30px 30px 30px;
  overflow-y: scroll;
  background: #efeff4;
  padding-bottom: 140px;
  min-height: auto;
  position: relative;
  .loading{
    position: fixed;
    /* background: red; */
    top: 0;
    left: 50%;
    bottom: 0;
    /* right: 0; */
    transform: translate(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.radar-tip{
  margin-top: 30px;
  color: #B4B5B7;
}
.speech-box{
  background: #fff;
  border-radius: 10px;
  padding: 30px 30px 40px 30px;
  margin-top: 30px;
}
.speech-name{
  font-size: 28px;
  font-weight: bold;
}
.speech-cont{
  margin-top: 20px;
  background: #F7F8F9;
  padding: 20px;
  display: flex;
  align-items: center;
  span{
    max-width: 225px;
    overflow: auto;
    white-space: nowrap;
    color: #4a4a4a !important;
  }
}
.speech-foot{
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin-top: 40px;
}
.radar-cont{
  padding-left: 65px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  img{
    width: 130px;
    height: 120px;
  }
}
.radar-rgh{
  margin-left: 10px;
  width: calc(100% - 150px);
  color: #8F8F8F;
  font-size: 26px;
  .radar-brief{
    -webkit-box-flex: 1;
    flex: 1;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
}
</style>
